import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/about/1.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'>ABOUT US</h6>
                <h2 className='title'>
                  It Is A Critical Component Of <span>Modern</span> Businesses
                </h2>
                <p className='content mb-4 mb-xl-5'>
                 At LevelPly, we are a dynamic team of IT and software experts dedicated to delivering innovative solutions that empower businesses to thrive in the digital age. With years of industry experience and a commitment to excellence, we provide tailored IT consulting and software development services that address your unique challenges. Our mission is to help you harness the full potential of technology, optimize your operations, and drive growth. We pride ourselves on our client-centric approach, ensuring your success remains our top priority. Let's collaborate to turn your vision into reality.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Mission</h5>
                        <p>
                         The mission of Our company is to leverage technology and expertise to empower businesses, organizations, and clients to achieve their digital transformation goals. This includes providing tailored solutions, optimizing IT infrastructure, and delivering innovative software that enhances efficiency and competitiveness.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Ambition</h5>
                        <p>
                         The vision of our company is to be a trusted partner and a pioneer in the world of technology, continuously striving to push the boundaries of innovation. We aim to remain at the forefront of IT solutions, adapting to evolving industry trends and emerging technologies, while consistently delivering excellence in service, customer satisfaction, and sustainable growth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
