import React from "react";
import {
  FaArrowRight,
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className='footer-area bg-black bg-cover'>
        <div className='footer-subscribe'>
          <div className='container'>
            <div
              className='footer-subscribe-inner bg-cover'
              style={{ backgroundImage: 'url("./assets/img/bg/6.png")' }}
            >
              <div className='row justify-content-lg-center'>
                <div className='col-lg-6'>
                  <h2 className='mb-lg-0 mb-3'>Play Field for your Future</h2>
                </div>
                <div className='col-lg-6 align-self-center text-lg-end'>
                  <a className='btn btn-black border-radius-0' href='https://calendly.com/info-6ka/30min' target="_blank">
                    Book A Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_about'>
                <div className='details'>
                  <p>
                   If you have any questions, feel free to contact us:
                  </p>
                  <p className='mt-3'>
                    <FaPhoneAlt /> +91 9632453367
                  </p>
                  <p className='mt-2'>
                    <FaEnvelope /> info@levelply.com 
                  </p>
                  <ul className='social-media'>
                    <li>
                      <a href="https://www.facebook.com/people/Levelply/61554138487478/?is_tour_completed=true">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/levelply/about/?viewAsMember=true">
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/levelply/">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UC9AXSjodpT3ybxX1tApvZiw">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            <br />
            <br />
            <br />
            <br />
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Our Services</h4>
                <ul>
                  <li>
                    <Link to='/service-details'>
                      <FaArrowRight />Development & IT
                    </Link>
                  </li>
                  <li>
                    <Link to='/service-details'>
                      <FaArrowRight />AI Servivces
                    </Link>
                  </li>
                  <li>
                    <Link to='/service-details'>
                      <FaArrowRight />Cyber Security
                    </Link>
                  </li>
                  <li>
                    <Link to='/service-details'>
                      <FaArrowRight />Sales & Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to='/service-details'>
                      <FaArrowRight />Admin & Customer Support
                    </Link>
                  </li>
                  <li>
                    <Link to='/service-details'>
                      <FaArrowRight /> Testing & QA
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <br />
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>Contact us</h4>
                <ul>
                  <li>
                    <div className='media'>
                      <div className='media-left'>
                        <img src='assets/img/widget/1.png' alt='blog' />
                      </div>
                      <div className='media-body align-self-center'>
                        <div className='post-info mb-2'>
                          <FaCalendarAlt />
                          <span>january 11, 2023</span>
                        </div>
                        <h6 className='title mb-0'>
                          <Link to='#'>
                            Social Media For Promote Business.
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className='media'>
                      <div className='media-left'>
                        <img src='assets/img/widget/2.png' alt='blog' />
                      </div>
                      <div className='media-body align-self-center'>
                        <div className='post-info mb-2'>
                          <FaCalendarAlt />
                          <span>july 6, 2023</span>
                        </div>
                        <h6 className='title mb-0'>
                          <Link to='#'>
                            Marketing For Base market watch
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>©Alphabeta Innovations Pvt Ltd 2023 | All Rights Reserved</p>
              </div>
              <div className='col-md-6 text-lg-end'>
                <a href='#'>Terms &amp; Conditions</a>
                <a href='#'>Privacy Policy</a>
                <a href='#'>Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
