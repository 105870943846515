import React from "react";
import { FaPlus } from "react-icons/fa";

const ProjectAreaOne = () => {
  return (
    <div className='project-section pd-top-120'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='section-title text-center'>
              <h6 className='sub-title'>Technologies</h6>
              <h2 className='title'>
               <span>Technologies</span> and <span>Platforms</span> We Work With
              </h2>
            </div>
          </div>
        </div>
        <nav className='text-center'>
          <div
            className='nav nav-tabs project-nav-tab d-inline-flex mb-5'
            id='nav-tab'
            role='tablist'
          >
            <button
              className='nav-link active'
              id='nav-tabs1-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs1'
              type='button'
              role='tab'
              aria-controls='nav-tabs1'
              aria-selected='true'
            >
              Development & IT
            </button>
            <button
              className='nav-link'
              id='nav-tabs2-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs2'
              type='button'
              role='tab'
              aria-controls='nav-tabs2'
              aria-selected='false'
            >
              AI Services
            </button>
            <button
              className='nav-link'
              id='nav-tabs3-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs3'
              type='button'
              role='tab'
              aria-controls='nav-tabs3'
              aria-selected='false'
            >
              Cyber Security
            </button>
            <button
              className='nav-link'
              id='nav-tabs4-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs4'
              type='button'
              role='tab'
              aria-controls='nav-tabs4'
              aria-selected='false'
            >
              Sales & Marketing
            </button>
            <button
              className='nav-link'
              id='nav-tabs5-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs5'
              type='button'
              role='tab'
              aria-controls='nav-tabs5'
              aria-selected='false'
            >
              Testing & QA
            </button>
            <button
              className='nav-link'
              id='nav-tabs6-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs6'
              type='button'
              role='tab'
              aria-controls='nav-tabs56'
              aria-selected='false'
            >
              Admin & Customer Support
            </button>
          </div>
        </nav>
        <div className='tab-content' id='nav-tabContent'>
          <div
            className='tab-pane fade show active'
            id='nav-tabs1'
            role='tabpanel'
            aria-labelledby='nav-tabs1-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/node js.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Node js
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/javascript.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Javascript
                    </a>
                  </div>
                </div>
              
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/react-native.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      React-Native
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/flutter.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Flutter
                    </a>
                  </div>
                </div>

              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/mongodb.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                     MongoDB
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/tech.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Many More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs2'
            role='tabpanel'
            aria-labelledby='nav-tabs2-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/opencv.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Computer Vision
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/nlp.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      NLP
                    </a>
                  </div>
                </div>

              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/tensorflow.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Tensorflow
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/terra.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Terraform
                    </a>
                  </div>
                </div>

              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/sci.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Scikit-Learn
                    </a>
                  </div>
                </div>

                <div className='single-project-inner'>
                  <img src='assets/img/gallery/ai.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs3'
            role='tabpanel'
            aria-labelledby='nav-tabs3-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/nmap1.jpg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Nmap
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/metasploit1.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Metasploit
                    </a>
                  </div>
                </div>

              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/burpsuite1.jpg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Burpsuite
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/nessus1.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Nessus
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/john1.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                    John the Ripper
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/cs.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Many more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs4'
            role='tabpanel'
            aria-labelledby='nav-tabs4-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/seo1.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      SEO
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/content1.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Content
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/marketing1.jpg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Marketing
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/crm.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      CRM
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/email.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Email Marketing
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/digital.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Many More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs5'
            role='tabpanel'
            aria-labelledby='nav-tabs4-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/selenium.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Selenium
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/appium.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      appium
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/jmeter.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Jmeter
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/Bugzilla.jpg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Bugzilla
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/jira.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Jira
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/testing.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Many More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs6'
            role='tabpanel'
            aria-labelledby='nav-tabs5-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/zoho.jpeg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Zoho
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/liveagent.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Liveagent
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/hiver.jpg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Hiver
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/help-scout.png' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                     Help Scout
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/Jira-Service.jpeg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Jira Service
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/customer.jpg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Many More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaOne;