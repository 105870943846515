import React from "react";
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceDetailsArea = () => {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> All Service list
                  </h5>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/service-details'>Development & IT </Link>
                    </li>
                    <li>
                      <Link to='/service-details'>AI Services</Link>
                    </li>
                    <li>
                      <Link to='/service-details'>Cyber Security</Link>
                    </li>
                    <li>
                      <Link to='/service-details'> Sales & Marketing</Link>
                    </li>
                    <li>
                      <Link to='/service-details'> Others Development</Link>
                    </li>
                  </ul>
                </div>
                <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    <img src='assets/img/about/9.png' alt='img' />
                  </div>
                  <div className='details'>
                    <a className='btn btn-base border-radius-5' href='#'>
                      Discover our company +
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/7.jpg' alt='img' />
                  </div>
                  <div className='details'>
                    <h4>
                      Making this the first true generator on the Internet
                    </h4>
                    <p>
                     At Levelply, our services are designed to cater 
                     to a wide spectrum of technology needs. Our IT 
                     consulting services encompass strategic planning, 
                     technology assessments, and digital transformation 
                     strategies. On the software development front, we 
                     specialize in custom software solutions, web and 
                     mobile app development, and legacy system modernization. 
                     We also offer expertise in cloud computing, cybersecurity, 
                     data analytics, and more. Our comprehensive services are 
                     backed by a team of experienced professionals committed to 
                     delivering excellence in every project. Explore the details 
                     of each service to discover how we can empower your organization 
                     to thrive in the digital age
                    </p>
                    <h4>Get touch have any question ?</h4>
                    <p>
                     We welcome your questions and are here to provide answers. 
                     Whether you have inquiries about our IT consulting services, 
                     software solutions, or need guidance on a technology-related 
                     matter, don't hesitate to reach out. Our team is ready to assist 
                     you in any way we can. Feel free to contact us via the provided 
                     channels, and we'll be happy to engage in a conversation that 
                     helps address your needs and objectives.
                    </p>
                    <div
                      className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                      id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            className='accordion-button'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            What services do you offer?
                          </button>
                        </h2>
                        <div
                          id='collapseOne'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                           We offer a range of services, including custom software development, web and mobile app development, cloud computing solutions, IT consulting, cybersecurity, data analytics, DevOps, quality assurance, legacy system modernization, blockchain development, UI/UX design, support and maintenance, ERP/CRM implementation, staff augmentation, training, compliance, and IT strategy development, tailored to meet specific client needs while staying updated on industry trends.
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            How long does it take for you to complete a project?
                          </button>
                        </h2>
                        <div
                          id='collapseTwo'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                           The time required to complete a project in companies can vary significantly depending on the complexity, scope, and specific requirements of the project. Small to medium-sized projects might take a few weeks to a few months, while larger and more intricate projects could extend to several months or even years. The timeline is typically determined during the project planning phase, taking into account factors such as project size, available resources, client expectations, and project milestones.
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            How much does it cost to work with your agency?
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                           The cost of working with ours company varies 
                           widely depending on factors such as the scope 
                           of the project, its complexity, the technologies 
                           involved, the expertise required, and the duration 
                           of the engagement. Projects can range from tens of 
                           thousands to millions of dollars. The cost is typically 
                           determined through a detailed project assessment and 
                           proposal process, where the company evaluates the client's 
                           specific needs and provides a customized estimate. It's 
                           important for clients and companies to collaborate closely 
                           to define a budget that aligns with project goals and 
                           expectations.
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4>Our Best it company</h4>
                    <p>
                     Our Best IT Company is a 
                     premier IT consulting and 
                     software development firm 
                     committed to excellence. 
                     With a proven track record 
                     of delivering exceptional 
                     solutions, we bring cutting-edge 
                     technology and strategic insights 
                     to businesses worldwide. Our expert 
                     team of professionals is dedicated 
                     to guiding clients through their 
                     digital transformation journeys, 
                     optimizing operations, and ensuring 
                     long-term success. We pride ourselves 
                     on our client-centric approach, where 
                     every project is a partnership aimed 
                     at achieving the best possible outcomes. 
                     Choose Our Best IT Company for innovative, 
                     tailored IT solutions that elevate your
                     business to new heights.
                    </p>
                    <div className='row'>
                      <div className='col-md-6'>
                        <ul className='single-list-inner style-check style-check mb-3'>
                          <li>
                            <FaCheckCircle /> Customized Solutions
                          </li>
                          <li>
                            <FaCheckCircle /> Client Success Stories
                          </li>
                          <li>
                            <FaCheckCircle /> Client-Centric Approach
                          </li>
                          <li>
                            <FaCheckCircle /> quality Assurance
                          </li>
                          <li>
                            <FaCheckCircle /> Timely Delivery
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsArea;
