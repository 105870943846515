import React, { useState } from 'react';

const CareerForm = () => {
  // State to store the form data
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    experience: '',
    previousPosition: '',
    expectedSalary: '',
    currentSalary: '',
    resume: null,
  });

  // Function to handle form data changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the form data, e.g., send to an API or server
    console.log('Form submitted with data:', formData);
  };

  return (
    


    <form onSubmit={handleSubmit} className="career-form">
      <input
        type="text"
        name="firstName"
        placeholder="First Name"
        value={formData.firstName}
        onChange={handleChange}
      />
      <input
        type="text"
        name="lastName"
        placeholder="Last Name"
        value={formData.lastName}
        onChange={handleChange}
      />
      <input
        type="tel"
        name="phone"
        placeholder="Phone Number"
        value={formData.phone}
        onChange={handleChange}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
      />
      <input
        type="text"
        name="experience"
        placeholder="Experience (years)"
        value={formData.experience}
        onChange={handleChange}
      />
      <input
        type="text"
        name="previousPosition"
        placeholder="Previous Position"
        value={formData.previousPosition}
        onChange={handleChange}
      />
      <input
        type="number"
        name="expectedSalary"
        placeholder="Expected Salary"
        value={formData.expectedSalary}
        onChange={handleChange}
      />
      <input
        type="number"
        name="currentSalary"
        placeholder="Current Salary"
        value={formData.currentSalary}
        onChange={handleChange}
      />
      <input
        type="file"
        name="resume"
        onChange={handleChange}
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default CareerForm;
