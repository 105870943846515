import React from "react";
import { FaArrowRight } from "react-icons/fa";

const CaseStudyArea = () => {
  return (
    <>
      {/* ====================  Case Study Area start ====================*/}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_info'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className='widget-info-inner'>
                    <h6>Clients</h6>
                    <p>Porter Victoria</p>
                    <h6>Category</h6>
                    <p>Website</p>
                    <h6>Date</h6>
                    <p>20 January, 2023</p>
                    <h6>Location</h6>
                    <p>Bengaluru</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/hrms.png' alt='img' />
                  </div>
                  <div className='details'>
                    <h4>
                      HRMS
                    </h4>
                    <p>
                    HRMS is a comprehensive software solution that streamlines 
                    and automates various HR functions within an organization. 
                    It centralizes employee data, facilitates recruitment and 
                    onboarding processes, tracks time and attendance, automates 
                    payroll management, and supports performance appraisal and 
                    talent development. HRMS also manages benefits administration, 
                    incorporates self-service portals for employees, ensures compliance 
                    with regulations, and utilizes data analytics for informed decision-making. 
                    By integrating these functions, HRMS enhances efficiency, reduces manual workload, 
                    minimizes errors, and promotes a strategic, data-driven approach to human resource 
                    management.
                    </p>
                    <h4>Get touch have any question ?</h4>
                    <p>
                     Certainly! If you have any questions or if there's anything specific you'd like to know or discuss, feel free to ask. I'm here to help!
                    </p>
                    <h4>Our Best it company</h4>
                    <p>
                     Implementing an HRMS in our esteemed IT company centralizes employee data, streamlines recruitment, enhances time and attendance tracking, automates payroll processing, strategically manages performance, facilitates efficient training programs, ensures seamless benefits administration, empowers employees through self-service portals, maintains compliance, and leverages data analytics for long-term success. This cohesive platform becomes a cornerstone, elevating efficiency, minimizing errors, and fostering a strategic, data-driven approach to human resource management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================  Case Study Area End ====================*/}
    </>
  );
};

export default CaseStudyArea;
