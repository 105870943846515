import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const CareerAreaOne = () => {
  return (
    <>
      {/* career area start */}
      <div className='career-section pd-top-120 '>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>ABOUT US</h6>
                <h2 className='title'>
                  The Power Of <span>Software</span> The Simplicity Of Solutions
                </h2>
              </div>
            </div>
          </div>
          <div className='career-wrap-area'>
            <nav>
              <div
                className='nav nav-tabs career-nav-tab'
                id='nav-tab'
                role='tablist'
              >
                <button
                  className='nav-link active'
                  id='nav-mission-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-mission'
                  type='button'
                  role='tab'
                  aria-controls='nav-mission'
                  aria-selected='true'
                >
                  Mission
                </button>
                <button
                  className='nav-link'
                  id='nav-vision-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-vision'
                  type='button'
                  role='tab'
                  aria-controls='nav-vision'
                  aria-selected='false'
                >
                  Vision
                </button>
                <button
                  className='nav-link'
                  id='nav-career-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-career'
                  type='button'
                  role='tab'
                  aria-controls='nav-career'
                  aria-selected='false'
                >
                  Careers
                </button>
                <button
                  className='nav-link'
                  id='nav-solution-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-solution'
                  type='button'
                  role='tab'
                  aria-controls='nav-solution'
                  aria-selected='false'
                >
                  Solutions
                </button>
              </div>
            </nav>
            <div className='tab-content' id='nav-tabContent'>
              <div
                className='tab-pane fade show active'
                id='nav-mission'
                role='tabpanel'
                aria-labelledby='nav-mission-tab'
              >
                <div className='career-wrap'>
                  <div className='row'>
                    <div className='col-lg-5'>
                      <img src='assets/img/about/16.png' alt='img' />
                    </div>
                    <div className='col-lg-7'>
                      <h3 className='mb-3'>The software development</h3>
                      <p className='mb-3'>
                       The mission of company is to 
                       leverage technology and expertise 
                       to empower businesses, organizations, 
                       and clients to achieve their digital 
                       transformation goals. This includes 
                       providing tailored solutions, optimizing 
                       IT infrastructure, and delivering innovative 
                       software that enhances efficiency and competitiveness.
                      </p>
                      <div className='row'>
                        <div className='col-md-6'>
                          <ul className='single-list-inner style-check style-heading style-check mb-3'>
                            <li>
                              <FaCheckCircle /> Client-Centric Approach
                            </li>
                            <li>
                              <FaCheckCircle /> Excellence in Execution
                            </li>
                          </ul>
                        </div>
                        <div className='col-md-6'>
                          <ul className='single-list-inner style-check style-heading style-check mb-3'>
                            <li>
                              <FaCheckCircle /> Continuous Learning and Innovation
                            </li>
                            <li>
                              <FaCheckCircle /> Long-Term Partnerships
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='nav-vision'
                role='tabpanel'
                aria-labelledby='nav-vision-tab'
              >
                <div className='career-wrap'>
                  <div className='row'>
                    <div className='col-lg-5'>
                      <img src='assets/img/about/17.png' alt='img' />
                    </div>
                    <div className='col-lg-7'>
                      <h3 className='mb-3'>The software development</h3>
                      <p className='mb-3'>
                       The vision of the company is to be a 
                       trusted partner and a pioneer in the 
                       world of technology, continuously 
                       striving to push the boundaries of 
                       innovation. We aim to remain at the 
                       forefront of IT solutions, adapting to 
                       evolving industry trends and emerging 
                       technologies, while consistently 
                       delivering excellence in service, 
                       customer satisfaction, and sustainable
                       growth.
                      </p>
                      <div className='row'>
                        <div className='col-md-6'>
                          <ul className='single-list-inner style-check style-heading style-check mb-3'>
                            <li>
                              <FaCheckCircle /> Leading Technological Advancements
                            </li>
                            <li>
                              <FaCheckCircle /> Global Reach
                            </li>
                          </ul>
                        </div>
                        <div className='col-md-6'>
                          <ul className='single-list-inner style-check style-heading style-check mb-3'>
                            <li>
                              <FaCheckCircle /> Innovative Solutions
                            </li>
                            <li>
                              <FaCheckCircle /> Sustainability
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='nav-career'
                role='tabpanel'
                aria-labelledby='nav-career-tab'
              >
                <div className='career-wrap'>
                  <div className='row'>
                    <div className='col-lg-5'>
                      <img src='assets/img/about/17.png' alt='img' />
                    </div>
                    <div className='col-lg-7'>
                      <h3 className='mb-3'>The software development</h3>
                      <p className='mb-3'>
                      At levelply, we believe in nurturing talent and fostering innovation. Join our team of passionate professionals and embark on a rewarding career in IT and software consulting. We offer a collaborative and inclusive work environment where your skills and ideas can flourish. Explore opportunities for growth, continuous learning, and making a meaningful impact in the ever-evolving world of technology. Join us in shaping the future of IT solutions.
                      </p>
                      <div className='row'>
                        <div className='col-md-6'>
                          <ul className='single-list-inner style-check style-heading style-check mb-3'>
                            <li>
                              <FaCheckCircle /> Innovative Projects
                            </li>
                            <li>
                              <FaCheckCircle /> Professional Growth
                            </li>
                          </ul>
                        </div>
                        <div className='col-md-6'>
                          <ul className='single-list-inner style-check style-heading style-check mb-3'>
                            <li>
                              <FaCheckCircle /> Collaborative Culture
                            </li>
                            <li>
                              <FaCheckCircle /> Work-Life Balance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='nav-solution'
                role='tabpanel'
                aria-labelledby='nav-solution-tab'
              >
                <div className='career-wrap'>
                  <div className='row'>
                    <div className='col-lg-5'>
                      <img src='assets/img/about/16.png' alt='img' />
                    </div>
                    <div className='col-lg-7'>
                      <h3 className='mb-3'>The software development</h3>
                      <p className='mb-3'>
                      At levelply, we specialize in delivering cutting-edge IT solutions that drive business success. Our comprehensive suite of services includes IT consulting, software development, cloud solutions, cybersecurity, and more. Whether you're seeking to optimize your IT infrastructure, develop custom software, or enhance your cybersecurity posture, we have the expertise and experience to tailor solutions that meet your specific needs. Discover how we can empower your organization to thrive in the digital age
                      </p>
                      <div className='row'>
                        <div className='col-md-6'>
                          <ul className='single-list-inner style-check style-heading style-check mb-3'>
                            <li>
                              <FaCheckCircle /> IT Consulting
                            </li>
                            <li>
                              <FaCheckCircle /> Custom Software Development
                            </li>
                          </ul>
                        </div>
                        <div className='col-md-6'>
                          <ul className='single-list-inner style-check style-heading style-check mb-3'>
                            <li>
                              <FaCheckCircle /> UI/UX Design
                            </li>
                            <li>
                              <FaCheckCircle /> Support & Maintenance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* career area start */}
    </>
  );
};

export default CareerAreaOne;
