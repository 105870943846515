import React from "react";
import { FaArrowRight } from "react-icons/fa";

const CaseStudyArea = () => {
  return (
    <>
      {/* ====================  Case Study Area start ====================*/}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_info'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className='widget-info-inner'>
                    <h6>Clients</h6>
                    <p>Porter Victoria</p>
                    <h6>Category</h6>
                    <p>Computer Vision & Ai</p>
                    <h6>Date</h6>
                    <p>20 January, 2023</p>
                    <h6>Location</h6>
                    <p>Bengaluru</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/7.png' alt='img' />
                  </div>
                  <div className='details'>
                    <h4>
                      Tester Alley
                    </h4>
                    <p>
                     Test automation tools are integral software
                     applications in the software development life 
                     cycle, automating test execution to ensure efficiency, 
                     reliability, and accuracy. These tools validate functionality, 
                     performance, and security, offering quicker and more thorough 
                     testing than manual methods. Key features encompass creating, 
                     executing, and managing test scripts, along with generating 
                     detailed reports. Supporting various testing types like 
                     functional, regression, performance, and load testing, these tools 
                     reduce testing time, minimize errors, and align with agile and DevOps 
                     needs for continuous testing. Integration with popular development and 
                     CI tools fosters collaboration. Examples include Selenium, Appium, JUnit, 
                     TestNG, and Cucumber, addressing diverse testing requirements. In the pursuit 
                     of high-quality software delivery at an accelerated pace, test automation tools 
                     are indispensable for achieving testing process efficiency and reliability.
                    </p>
                    <h4>Get touch have any question ?</h4>
                    <p>
                     If you have any questions or need further clarification regarding the information provided about test automation tools, feel free to ask. I'm here to help!
                    </p>
                    <h4>Our Best it company</h4>
                    <p>
                    Selecting the best IT company for test automation tools involves considering factors such as project requirements, team expertise, and the nature of projects. Reputable companies include Selenium, known for its open-source web application automation framework, and Applitools, offering visual testing solutions. SmartBear, with TestComplete, focuses on web, mobile, and desktop application testing. Tricentis specializes in continuous testing with the Tosca platform, while Appium is an open-source tool for mobile application automation. To make an informed choice, assess needs, prioritize factors like ease of use and scalability, and stay updated on reviews and industry developments.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================  Case Study Area End ====================*/}
    </>
  );
};

export default CaseStudyArea;
