let serviceList = [
  {
    title: "Development & IT",
    des: "Empowering startups with cutting-edge Development & IT services to fuel innovation and accelerate digital transformation.",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "AI Services",
    des: "Revolutionize your startup with cutting-edge AI services, driving innovation, efficiency, and growth through advanced data analytics, machine learning, and automation solutions.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Sales & Marketing",
    des: "Empowering startups with strategic sales and marketing solutions to accelerate growth and establish a strong market presence.",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Cyber Security",
    des: "Empowering startups with robust Cyber Security solutions to safeguard digital assets and ensure resilient protection against evolving cyber threats.",
    img: "assets/img/service-icon/18.svg",
  },
  {
    title: "Admin & Customer Support",
    des: "Efficient and personalized admin and customer support solutions tailored for startup success.",
    img: "assets/img/service-icon/17.svg",
  },

  {
    title: "Testing & QA",
    des: "Empowering startup success through comprehensive Testing & QA Support services, ensuring seamless product quality and performance.",
    img:"assets/img/service-icon/16.svg",
  },
];

export default serviceList;
