import React from "react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaChevronRight,
  FaRegFolderOpen,
  FaPlus,
  FaRegUser,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const careers = () => {
  return (
    <>
      
      <div className='blog-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='single-blog-inner'>
                <div className='thumb'>
                  <img src='assets/img/blog/1.png' alt='img' />
                </div>
                <div className='details'>
                  <ul className='blog-meta'>
                    <li>
                      <FaRegUser /> By Admin
                    </li>
                    <li>
                      <FaRegFolderOpen /> Development
                    </li>
                  </ul>
                  <h2 className='title'>
                    <Link to='#'>
                      Flutter Devloper
                    </Link>
                  </h2>
                  <p>
                   Join levelply, a leading technology company specializing in cutting-edge mobile application development. We're on the lookout for an experienced Flutter Developer to contribute to our innovative projects. As a Flutter Developer at levelply, you'll be tasked with designing, developing, and maintaining high-quality mobile applications using the Flutter framework. We're seeking candidates with a strong background in mobile app development, a deep understanding of Flutter, and a proven track record of successful project deliveries. Your responsibilities will include mobile app development, code quality maintenance, collaboration with cross-functional teams, performance optimization, API integration, bug fixing, continuous learning, code reviews, documentation, and mentorship. Requirements include a Bachelor's degree in Computer Science or a related field, 5+ years of mobile app development experience, a strong portfolio in Flutter development, proficiency in Flutter framework and Dart programming, experience with version control systems and APIs, and excellent problem-solving and collaboration skills. levelply is an equal opportunity employer, welcoming candidates from all backgrounds. Interested candidates can apply by submitting their resume, portfolio, and a cover letter to info@levelply.com with the subject line "Flutter Developer Application." Applications will be accepted until the specified closing date.
                  </p>
                  <Link
                    className='btn btn-border-base mt-3'
                    to='/Career-forms'
                  >
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
              <div className='single-blog-inner'>
                <div className='thumb'>
                  <img src='assets/img/blog/2.png' alt='img' />
                </div>
                <div className='details'>
                  <ul className='blog-meta'>
                    <li>
                      <FaRegUser /> By Admin
                    </li>
                    <li>
                      <FaRegFolderOpen /> Development
                    </li>
                  </ul>
                  <h2 className='title'>
                    <Link to='/blog-details'>
                      Android Developer
                    </Link>
                  </h2>
                  <p>
                  Join levelply, a leading technology company at the forefront of cutting-edge mobile application development. We are actively seeking an experienced Android Developer to contribute to our innovative projects. As an Android Developer at levelply, your role will involve designing, developing, and maintaining high-quality mobile applications using the latest Android technologies. We are looking for candidates with a solid background in mobile app development, a profound understanding of Android development, and a demonstrated track record of successfully delivering projects. Your responsibilities will encompass various aspects, including mobile app development, ensuring code quality, collaborating with cross-functional teams, optimizing performance, integrating APIs, resolving bugs, continuous learning, participating in code reviews, maintaining documentation, and providing mentorship. Requirements for this position include a Bachelor's degree in Computer Science or a related field, a minimum of 5 years of experience in mobile app development, a robust portfolio showcasing Android development expertise, proficiency in Android frameworks and programming languages, familiarity with version control systems and APIs, and exceptional problem-solving and collaboration skills. levelply is an equal opportunity employer, embracing candidates from diverse backgrounds. If you are passionate about Android development and want to be part of an innovative team, we invite you to apply by submitting your resume, portfolio, and a cover letter to info@levelply.com with the subject line "Android Developer Application." Applications will be accepted until the specified closing date.
                  </p>
                  <Link
                    className='btn btn-border-base mt-3'
                    to='/Career-forms'
                  >
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
              <div className='single-blog-inner'>
                <div className='thumb'>
                  <img src='assets/img/blog/3.png' alt='img' />
                </div>
                <div className='details'>
                  <ul className='blog-meta'>
                    <li>
                      <FaRegUser /> By Admin
                    </li>
                    <li>
                      <FaRegFolderOpen /> Category
                    </li>
                  </ul>
                  <h2 className='title'>
                    <Link to='/blog-details'>
                      Full Stack Developer
                    </Link>
                  </h2>
                  <p>
                   Join levelply, a forefront technology company specializing in cutting-edge mobile application development. We are actively seeking an experienced Full Stack Developer to contribute to our innovative projects. As a Full Stack Developer at levelply, you will play a pivotal role in designing, developing, and maintaining high-quality web and mobile applications across the entire technology stack. We are looking for candidates with a robust background in full-stack development, showcasing proficiency in both front-end and back-end technologies, and a proven track record of successfully delivering comprehensive projects.

                   Your responsibilities will span a wide range, including but not limited to full-stack development, ensuring code quality and system reliability, collaborating with cross-functional teams, optimizing application performance, integrating APIs, resolving technical issues, continuous learning, participating in code reviews, maintaining comprehensive documentation, and providing mentorship to junior developers.

                   Requirements for this position include a Bachelor's degree in Computer Science or a related field, a minimum of 5 years of experience in full-stack development, a diverse portfolio demonstrating proficiency in both front-end and back-end technologies, familiarity with popular frameworks and programming languages, experience with version control systems and APIs, and exceptional problem-solving and collaboration skills.

                   levelply is an equal opportunity employer, embracing candidates from diverse backgrounds. If you are passionate about full-stack development and want to be part of an innovative team, we invite you to apply by submitting your resume, portfolio, and a cover letter to info@levelply.com with the subject line "Full Stack Developer Application." Applications will be accepted until the specified closing date.
                  </p>
                  <Link
                    className='btn btn-border-base mt-3'
                    to='/Career-forms'
                  >
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
              <div className='pagination'>
                <a className='prev page-numbers' href='http://icare.local/'>
                  <FaAngleLeft />
                </a>
                <a className='page-numbers' href='http://icare.local/'>
                  1
                </a>
                <span className='page-numbers current'>2</span>
                <a className='page-numbers' href='http://icare.local/page/3/'>
                  3
                </a>
                <a className='page-numbers' href='http://icare.local/page/4/'>
                  4
                </a>
                <a
                  className='next page-numbers'
                  href='http://icare.local/page/3/'
                >
                  <FaAngleRight />
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar'>
              
                <div className='widget widget_search'>
                  <form className='search-form'>
                    <div className='form-group'>
                      <input type='text' placeholder='Key word' />
                    </div>
                    <button className='submit-btn' type='submit'>
                      <FaChevronRight />
                    </button>
                  </form>
                </div>

                <div className='widget widget_catagory'>
                  <h4 className='widget-title'>Catagory</h4>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='#'>
                        Development & IT 
                      </Link>
                    </li>
                    <li>
                      <Link to='#'>
                        AI Service
                      </Link>
                    </li>
                    <li>
                      <Link to='#'>
                        Cyber Security
                      </Link>
                    </li>
                    <li>
                      <Link to='#'>
                        Sales & Markrting
                      </Link>
                    </li>
                    <li>
                      <Link to='#'>
                        Testing & QA
                      </Link>
                    </li>
                    <li>
                      <Link to='#'>
                        Admin & CustomerSupport
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='widget widget_tag_cloud mb-0'>
                  <h4 className='widget-title'>Tags</h4>
                  <div className='tagcloud'>
                    <a href='#'>Development & IT</a>
                    <a href='#'>AI</a>
                    <a href='#'>Cyber Security</a>
                    <a href='#'>Sales & Markrting</a>
                    <a href='#'>Testing & QA</a>
                    <a href='#'>DevOps</a>
                    <a href='#'>Admin & CustomerSupport</a>
                    <a href='#'>Data Analyst</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== Blog Group End ===============*/}
    </>
  );
};

export default careers;
