import React, { useState } from 'react';
import axios from 'axios';

const DemoBookingForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        date: '',
        time: '',
        description: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://yourserver.com/schedule-demo', formData);
            console.log(response.data);
            // Redirect to Calendly for booking after successful form submission
            window.location.href = 'https://calendly.com/leveply/30min';
        } catch (error) {
            console.error('Error scheduling demo:', error);
            // Handle error here
        }
    };

    return (
        <form onSubmit={handleSubmit} className="demo-booking-form">
            <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" />
            <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" />
            <input type="date" name="date" value={formData.date} onChange={handleChange} />
            <input type="time" name="time" value={formData.time} onChange={handleChange} />
            <textarea 
                name="description" 
                value={formData.description} 
                onChange={handleChange} 
                placeholder="Description"
                rows="4"
            />
            <button type="submit">Book Demo</button>
        </form>
    );
};

export default DemoBookingForm;
