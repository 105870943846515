import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import DemoBookingForm from "../components/Demo";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";

const Bookdemo = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"BookDemoForm"} />

      {/* Contact Main */}
      <DemoBookingForm/>

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default Bookdemo;
